import { useState, useEffect } from "react";
import { Letter } from "./components/Letter";
import { HardWork } from "./components/HardWork";
import { Modal } from "./components/Modal";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div >
      <HardWork />
      <Letter />
      <Modal />
    </div>
  );
};

export default App;
