export const Modal = (props) => {
  return (
    <div
      className="container containerModal midContainer noTopMargin padding40-top padding40-bottom padding40H noBorder borderSolid border3px cornersAll radius10 shadow0 bgNoRepeat emptySection"
      id="modalPopup"
      data-title="Modal"
      data-block-color="0074C7"
      style={{
        marginTop: "100px",
        paddingTop: "40px",
        paddingBottom: "40px",
        outline: "none",
        backgroundColor: "rgb(255, 255, 255)",
        display: "none",
      }}
      data-trigger="none"
      data-animate="top"
      data-delay="0"
    >
      <div className="containerInner ui-sortable">
        <div
          className="row bgCover noBorder borderSolid border3px cornersAll shadow0 P0-top P0-bottom P0H noTopMargin radius20"
          id="row--97644"
          data-trigger="none"
          data-animate="fade"
          data-delay="500"
          data-title="1 column row"
          style={{
            paddingTop: "20px",
            paddingBottom: "20px",
            margin: "0px",
            outline: "none",
            backgroundColor: "rgba(0, 0, 0, 0.25)",
          }}
        >
          <div
            id="col-full-178"
            className="col-md-12 innerContent col_left"
            data-col="full"
            data-trigger="none"
            data-animate="fade"
            data-delay="500"
            data-title="1st column"
            style={{ outline: "none" }}
          >
            <div
              className="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
              style={{ padding: "0 10px" }}
            >
              <div
                className="de elHeadlineWrapper ui-droppable de-editable"
                id="tmp_headline1-87109"
                data-de-type="headline"
                data-de-editing="false"
                data-title="headline"
                data-ce="true"
                data-trigger="none"
                data-animate="fade"
                data-delay="500"
                style={{
                  marginTop: "30px",
                  outline: "none",
                  cursor: "pointer",
                }}
                aria-disabled="false"
              >
                <h1
                  className="ne elHeadline hsSize3 lh4 elMargin0 elBGStyle0 hsTextShadow0"
                  style={{ textAlign: "center", fontSize: "32px" }}
                  data-bold="inherit"
                  data-gramm="false"
                >
                  <b>Open Enrollment is now Closed. </b>
                </h1>
              </div>
              <div
                className="de elHeadlineWrapper ui-droppable de-editable"
                id="tmp_subheadline-88450"
                data-de-type="headline"
                data-de-editing="false"
                data-title="sub-headline"
                data-ce="true"
                data-trigger="none"
                data-animate="fade"
                data-delay="500"
                data-gramm="false"
                style={{
                  marginTop: "15px",
                  outline: "none",
                  cursor: "pointer",
                }}
                aria-disabled="false"
              >
                <h2
                  className="ne elHeadline hsSize2 lh3 elMargin0 elBGStyle0 hsTextShadow0"
                  style={{ textAlign: "center", fontSize: "23px" }}
                  data-bold="inherit"
                  data-gramm="false"
                >
                  To be notified when the Den is open give us your email below.
                  We will then email you when its open
                </h2>
              </div>
              <div
                className="de elInputWrapper de-input-block elAlign_center elMargin0 ui-droppable de-editable"
                id="input-65438"
                data-de-type="input"
                data-de-editing="false"
                data-title="input"
                data-ce="false"
                data-trigger="none"
                data-animate="fade"
                data-delay="500"
                type="name"
                style={{
                  marginTop: "30px",
                  outline: "none",
                  cursor: "pointer",
                }}
                aria-disabled="false"
              >
                <input
                  type="name"
                  placeholder="Your Name"
                  name="name"
                  className="elInput elInput100 elAlign_left elInputMid elInputStyl0 elInputBG1 elInputBR5 elInputI0 elInputIBlack elInputIRight ceoinput required1"
                  data-type="extra"
                ></input>
              </div>
              <div
                className="de elInputWrapper de-input-block elAlign_center elMargin0 ui-droppable de-editable"
                id="tmp_input-97466"
                data-de-type="input"
                data-de-editing="false"
                data-title="input"
                data-ce="false"
                data-trigger="none"
                data-animate="fade"
                data-delay="500"
                type="email"
                style={{
                  marginTop: "30px",
                  outline: "none",
                  cursor: "pointer",
                }}
                aria-disabled="false"
              >
                <input
                  type="email"
                  placeholder="Your Email Address Here..."
                  name="email"
                  className="elInput elInput100 elAlign_left elInputMid elInputStyl0 elInputBG1 elInputBR5 elInputI0 elInputIBlack elInputIRight ceoinput required1"
                  data-type="extra"
                ></input>
              </div>
              <div
                className="de elInputWrapper de-input-block elAlign_center elMargin0 ui-droppable de-editable"
                id="input-88111"
                data-de-type="input"
                data-de-editing="false"
                data-title="input"
                data-ce="false"
                data-trigger="none"
                data-animate="fade"
                data-delay="500"
                type="phone"
                style={{
                  marginTop: "30px",
                  outline: "none",
                  cursor: "pointer",
                }}
                aria-disabled="false"
              >
                <input
                  type="phone"
                  placeholder="Mobile Number"
                  name="phone"
                  className="elInput elInput100 elAlign_left elInputMid elInputStyl0 elInputBG1 elInputBR5 elInputI0 elInputIBlack elInputIRight ceoinput required1"
                  data-type="extra"
                ></input>
              </div>
              <div
                className="de elHeadlineWrapper ui-droppable de-editable"
                id="tmp_cb_headline1-81585"
                data-de-type="cb_headline"
                data-de-editing="false"
                data-title="checkbox headline"
                data-ce="true"
                data-trigger="none"
                data-animate="fade"
                data-delay="500"
                data-cb-required="yes"
                data-field-name=""
                style={{
                  marginTop: "30px",
                  outline: "none",
                  cursor: "pointer",
                }}
                aria-disabled="false"
              >
                <input
                  className="elInput elHeadlineCheckbox required0"
                  type="checkbox"
                  name="custom_type"
                  data-type="extra"
                  data-custom-type="sms_consent"
                  value="false"
                  data-required="yes"
                  data-eu-only="no"
                  tabIndex="0"
                ></input>
                <div
                  className="ne elHeadline hsSize3 lh4 elMargin0 elBGStyle0 hsTextShadow0"
                  style={{ textAlign: "left", fontSize: "16px" }}
                  data-bold="inherit"
                  data-gramm="false"
                >
                  By signing up via text, you agree to receive recurring
                  automated marketing text messages at the cell phone used to
                  sign up. Consent is not a condition of purchase. msg and data
                  rates may apply. View terms and conditions.
                </div>
              </div>
              <div
                className="de elBTN elAlign_center elMargin0 ui-droppable de-editable"
                id="tmp_button-33547"
                data-de-type="button"
                data-de-editing="false"
                data-title="button"
                data-ce="false"
                data-trigger="none"
                data-animate="fade"
                data-delay="500"
                style={{
                  marginTop: "25px",
                  outline: "none",
                  cursor: "pointer",
                }}
                data-elbuttontype="1"
                aria-disabled="false"
              >
                <a
                  href="#submit-form"
                  className="elButton elButtonSize1 elButtonColor1 elButtonRounded elButtonPadding2 elBtnVP_10 elButtonCorner3 elButtonFluid elBtnHP_25 elBTN_b_1 elButtonShadowN1 elButtonTxtColor1"
                  //</div>style="color: rgb(255, 255, 255); font-weight: 600; background-color: rgb(228, 59, 44); font-size: 40px;"
                >
                  <span className="elButtonMain">Notify Me! </span>
                  <span className="elButtonSub"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="closeLPModal">
        <img
          src="https://assets.clickfunnels.com/images/closemodal.png"
          alt=""
        ></img>
      </div>
    </div>
  );
};
