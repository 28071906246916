export const Letter = (props) => {
  return (
    <div
      className="container wideContainer noTopMargin padding20-top padding20-bottom padding40H noBorder borderSolid border3px cornersAll bgNoRepeat emptySection shadow20 radius20"
      id="section--39444"
      data-title="SECTION 2"
      data-block-color="0074C7"
      style={{
        paddingTop: "20px",
        paddingBottom: "20px",
        outline: "none",
        marginTop: "-20px",
        backgroundColor: "rgba(0, 0, 0, 0)",
      }}
      data-trigger="none"
      data-animate="fade"
      data-delay="500"
    >
      <div className="containerInner ui-sortable">
        {/* THIS IS STILL THE DENS
        <div
          className="row bgCover noBorder borderSolid border3px cornersAll radius0 P0-top P0-bottom P0H noTopMargin shadow0"
          id="row--84660"
          data-trigger="none"
          data-animate="fade"
          data-delay="500"
          data-title="1 column row"
          style={{
            paddingTop: "20px",
            paddingBottom: "20px",
            margin: "0px 10px",
            width: "auto",
            outline: "none",
          }}
        >
          <div
            id="col-full-122"
            className="col-md-12 innerContent col_left"
            data-col="full"
            data-trigger="none"
            data-animate="fade"
            data-delay="500"
            data-title="1st column"
            style={{ outline: "none" }}
          >
            <div
              className="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
              style={{ padding: "0 10px" }}
            >
              <div
                className="de elImageWrapper de-image-block elAlign_center elMargin0 ui-droppable de-editable"
                id="tmp_image-70813"
                data-de-type="img"
                data-de-editing="false"
                data-title="image"
                data-ce="false"
                data-trigger="none"
                data-animate="fade"
                data-delay="500"
                style={{
                  marginTop: "25px",
                  outline: "none",
                  cursor: "pointer",
                }}
                aria-disabled="false"
              >
                <img
                  src="//www.lionsnotsheepden.com/hosted/images/images/stock/arrows/black/down-3.png"
                  className="elIMG ximg noShadow"
                  alt=""
                  width="300"
                ></img>
              </div>
            </div>
          </div>
        </div> */}
        <div
          className="row bgCover noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
          id="row--67994"
          data-trigger="none"
          data-animate="fade"
          data-delay="500"
          data-title="2 column row"
          style={{
            paddingTop: "20px",
            paddingBottom: "20px",
            margin: "0px",
            outline: "none",
          }}
        >
          <div
            id="col-left-174"
            className="col-md-6 innerContent col_left ui-resizable"
            data-col="left"
            data-trigger="none"
            data-animate="fade"
            data-delay="500"
            data-title="1st column"
            style={{ outline: "none" }}
          >
            <div
              className="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
              style={{ padding: "0 10px" }}
            >
              <div
                className="de elImageWrapper de-image-block elAlign_center elMargin0 ui-droppable de-editable"
                id="tmp_image-30328"
                data-de-type="img"
                data-de-editing="false"
                data-title="image"
                data-ce="false"
                data-trigger="none"
                data-animate="fade"
                data-delay="500"
                style={{ marginTop: "5px", outline: "none", cursor: "pointer" }}
                aria-disabled="false"
              >
                <img
                  src="img/D4 2.png"
                  className="elIMG ximg shadow40 thumbnailImageDark"
                  alt=""
                ></img>
              </div>
            </div>
          </div>
          <div
            id="col-right-140"
            className="col-md-6 innerContent col_right ui-resizable"
            data-col="right"
            data-trigger="none"
            data-animate="fade"
            data-delay="500"
            data-title="2nd column"
            style={{ outline: "none" }}
          >
            <div
              className="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
              style={{ padding: "0 10px" }}
            >
              <div
                className="de elHeadlineWrapper ui-droppable de-editable"
                id="tmp_subheadline-53698"
                data-de-type="headline"
                data-de-editing="false"
                data-title="sub-headline"
                data-ce="true"
                data-trigger="none"
                data-animate="fade"
                data-delay="500"
                data-gramm="false"
                style={{
                  marginTop: "30px",
                  outline: "none",
                  cursor: "pointer",
                }}
                aria-disabled="false"
              >
                <div
                  className="ne elHeadline hsSize2 lh3 elMargin0 elBGStyle0 hsTextShadow0"
                  style={{ textAlign: "center", fontSize: "23px" }}
                  data-bold="inherit"
                  data-gramm="false"
                ></div>
              </div>
              <div
                className="de elHeadlineWrapper ui-droppable de-editable"
                id="tmp_headline1-33869"
                data-de-type="headline"
                data-de-editing="false"
                data-title="headline"
                data-ce="true"
                data-trigger="none"
                data-animate="fade"
                data-delay="500"
                style={{
                  marginTop: "-20px",
                  outline: "none",
                  cursor: "pointer",
                  fontFamily: "Rock Salt, Helvetica, sans-serif",
                }}
                aria-disabled="false"
                data-google-font="Rock+Salt"
              >
                <div
                  className="ne elHeadline hsSize3 lh4 elMargin0 elBGStyle0 hsTextShadow0"
                  style={{ textAlign: "left", fontSize: "26px" }}
                  data-bold="inherit"
                  data-gramm="false"
                >
                  <b>From: Miguel Chavez</b>
                  <div>
                    <b>Jan, 2022 </b>
                  </div>
                </div>
              </div>
              <div
                className="de elHeadlineWrapper ui-droppable de-editable"
                id="tmp_paragraph-61354"
                data-de-type="headline"
                data-de-editing="false"
                data-title="Paragraph"
                data-ce="true"
                data-trigger="none"
                data-animate="fade"
                data-delay="500"
                style={{
                  marginTop: "30px",
                  outline: "none",
                  cursor: "pointer",
                }}
                aria-disabled="false"
              >
                <div
                  className="ne elHeadline hsSize1 lh5 elMargin0 elBGStyle0 hsTextShadow0"
                  data-bold="inherit"
                  style={{ textAlign: "left", fontSize: "20px" }}
                  data-gramm="false"
                >
                  <div>"How the hell do I CHANGE MY LIFE?" </div>
                  <br />
                  <div>
                    "How do I build a successful business, with out sacrificing
                    my life?"
                  </div>
                  <br />
                  <div>"How do I control my inner monster?"</div>
                  <div>
                    <br />
                    These questions seemed so simple, but I didn't have any
                    answers for them.
                  </div>
                  <br />
                  <div>
                    I did not have coaches or mentors to help me see or do
                    things differently.
                  </div>
                  <br />
                  <div>
                    Until I started to get control of my life. Removing the
                    choice of, if I do it or do not do it.
                  </div>
                  <br />
                  <div>Replacing choice with daily actions!</div>
                  <br />
                  <div>
                    So if you want to <u>make more money</u>,{" "}
                    <u>
                      spend more time doing the sh*t you love with people you
                      love
                    </u>
                    , <u>and build your EPIC LIFE</u>, keep reading...
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="row bgCover noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
          id="row--54362"
          data-trigger="none"
          data-animate="fade"
          data-delay="500"
          data-title="1 column row"
          style={{
            paddingTop: "20px",
            paddingBottom: "20px",
            margin: "-40px 0px 0px",
            outline: "none",
          }}
        >
          <div
            id="col-full-158"
            className="col-md-12 innerContent col_left"
            data-col="full"
            data-trigger="none"
            data-animate="fade"
            data-delay="500"
            data-title="1st column"
            style={{ outline: "none" }}
          >
            <div
              className="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
              style={{ padding: "0 10px" }}
            >
              <div
                className="de elHeadlineWrapper ui-droppable de-editable"
                id="tmp_headline1-87457"
                data-de-type="headline"
                data-de-editing="false"
                data-title="headline"
                data-ce="true"
                data-trigger="none"
                data-animate="fade"
                data-delay="500"
                style={{
                  marginTop: "30px",
                  outline: "none",
                  cursor: "pointer",
                  fontFamily: "Fjalla One, Helvetica, sans-serif",
                }}
                aria-disabled="false"
                data-google-font="Fjalla+One"
              >
                <div
                  className="ne elHeadline hsSize3 lh4 elMargin0 elBGStyle0 hsTextShadow0"
                  style={{ textAlign: "center", fontSize: "56px" }}
                  data-bold="inherit"
                  data-gramm="false"
                >
                  <div>
                    <b>
                      <u>YOU DON'T NEED MORE INFORMATION</u>
                    </b>
                  </div>
                </div>
              </div>
              <div
                className="de elHeadlineWrapper ui-droppable hiddenElementTools de-editable"
                id="tmp_paragraph-60315"
                data-de-type="headline"
                data-de-editing="false"
                data-title="Paragraph"
                data-ce="true"
                data-trigger="none"
                data-animate="fade"
                data-delay="500"
                style={{
                  marginTop: "50px",
                  outline: "none",
                  cursor: "pointer",
                }}
                aria-disabled="false"
              >
                <div
                  className="ne elHeadline hsSize1 lh5 elMargin0 elBGStyle0 hsTextShadow0 lh3"
                  data-bold="inherit"
                  style={{ textAlign: "left", fontSize: "20px" }}
                  data-gramm="false"
                >
                  <div>
                    If you know anything about me you know I don't dance around
                    words. So I'm going to get right to it.
                  </div>
                  <br />
                  <div>
                    <b>
                      <u>YOU DO NOT NEED MORE FUCKING INFORMATION</u>
                    </b>{" "}
                    to get your life disciplined.
                  </div>
                  <br />
                  <div>Think about it. </div>
                  <br />
                  <div>
                    You can GOOGLE{" "}
                    <u>
                      <i>
                        "HOW TO MAKE A MILLION DOLLARS... HOW TO HAVE A GREAT
                        MARRIAGE ... HOW TO BUILD A 6 PACK ... HOW TO BE A GOOD
                        PARENT ... HOW TO WORK LESS AND MAKE MORE ..."
                      </i>
                    </u>
                  </div>
                  <br />
                  <div>
                    And you will have page after page with answers to these
                    questions.{" "}
                  </div>
                  <br />
                  <div>Business videos. </div>
                  <div>Relationship videos. </div>
                  <div>Workout videos.</div>
                  <div>Parenting videos.</div>
                  <br />
                  <div>MILLIONS OF THEM! </div>
                  <br />
                  <div>
                    Hell, if you wanted to build an Electric Humvee, Youtube
                    literally has video blueprints to build a the whole thing!
                    (google it, I'm not kidding)
                  </div>
                  <br />
                  <div>
                    So if the answer to basically every question you could ever
                    think of is available to you,
                    <u>
                      <i>WHY DON'T YOU HAVE EXACTLY WHAT YOU WANT?</i>
                    </u>
                  </div>
                  <div>
                    <span
                      style={{ backgroundColor: "initial", color: "inherit" }}
                    >
                      <br />
                    </span>
                  </div>
                  <div>
                    <span
                      style={{ backgroundColor: "initial", color: "inherit" }}
                    >
                      - A 6 PACK
                    </span>
                  </div>
                  <div>
                    <span
                      style={{ backgroundColor: "initial", color: "inherit" }}
                    >
                      - MORE MONEY
                    </span>
                  </div>
                  <div>
                    <span
                      style={{ backgroundColor: "initial", color: "inherit" }}
                    >
                      - A RADICALLY DEEP RELATIONSHIP
                    </span>
                  </div>
                  <div>
                    <span
                      style={{ backgroundColor: "initial", color: "inherit" }}
                    >
                      - A BOOMING BUSINESS
                    </span>
                  </div>
                  <div>
                    <span
                      style={{ backgroundColor: "initial", color: "inherit" }}
                    >
                      - LESS CHAOS AND DRAMA
                    </span>
                  </div>
                  <div>
                    <span
                      style={{ backgroundColor: "initial", color: "inherit" }}
                    >
                      - ALL THE FREE TIME IN THE WORLD
                    </span>
                  </div>
                  <div>
                    <span
                      style={{ backgroundColor: "initial", color: "inherit" }}
                    >
                      <br />
                    </span>
                  </div>
                  <div>
                    <span
                      style={{ backgroundColor: "initial", color: "inherit" }}
                    >
                      It's because you are doing all the shit that looks good on
                      paper, but DOES NOTHING TO GET YOU RESULTS.{" "}
                    </span>
                  </div>
                  <div>
                    <span
                      style={{ backgroundColor: "initial", color: "inherit" }}
                    >
                      <br />
                    </span>
                  </div>
                  <div>
                    <span
                      style={{ backgroundColor: "initial", color: "inherit" }}
                    >
                      You can read all the self help books but still be in the
                      same situation as last month if you don't{" "}
                      <b>
                        <u>DO THINGS DIFFERENTLY.</u>
                      </b>
                    </span>
                  </div>
                  <div>
                    <span
                      style={{ backgroundColor: "initial", color: "inherit" }}
                    >
                      <br />
                    </span>
                  </div>
                  <div>
                    <span
                      style={{ backgroundColor: "initial", color: "inherit" }}
                    >
                      You can learn to meditate and wear all the cool beads but
                      unless you get the garbage out of your life it'll continue
                      to be filled with shit!{" "}
                    </span>
                  </div>
                  <div>
                    <span
                      style={{ backgroundColor: "initial", color: "inherit" }}
                    >
                      <br />
                    </span>
                  </div>
                  <div>
                    <span
                      style={{ backgroundColor: "initial", color: "inherit" }}
                    >
                      You can google <u>HOW TO MAKE A MILLION DOLLARS</u> but
                      unless you have an{" "}
                      <u>EXACT BLUEPRINT OF WHAT YOU ARE GOING TO DO</u> you'll
                      stay broke.
                    </span>
                  </div>
                  <div>
                    <span
                      style={{ backgroundColor: "initial", color: "inherit" }}
                    >
                      <br />
                    </span>
                  </div>
                  <div>
                    <span
                      style={{ backgroundColor: "initial", color: "inherit" }}
                    >
                      <b>
                        <i>
                          <u>
                            INFORMATION DOES NOT GET YOU RESULTS, NEW ACTION
                            GETS YOU RESULTS.
                          </u>
                        </i>
                      </b>
                    </span>
                  </div>
                  <div>
                    <span
                      style={{ backgroundColor: "initial", color: "inherit" }}
                    >
                      <br />
                    </span>
                  </div>
                  <div>
                    <span
                      style={{ backgroundColor: "initial", color: "inherit" }}
                    >
                      <br />
                    </span>
                  </div>
                  <div>
                    Because after years of banging my head against the wall over
                    and over. I finally figured out how to get{" "}
                    <b>DIFFERENT &, BETTER RESULTS</b> in my life.
                  </div>
                  <br />
                  <div>
                    Not just for a minute or a month or during the fad diet or
                    peak selling season.{" "}
                  </div>
                  <br />
                  <div>
                    <u>
                      <b>BIG RESULTS.</b>
                    </u>
                  </div>
                  <div>
                    <u>
                      <b>
                        <br />
                      </b>
                    </u>
                  </div>
                  <div>
                    <u>
                      <b>LASTING RESULTS</b>
                    </u>{" "}
                    day after day with my:
                    <br />
                  </div>
                  <div>Discipline in Mentality</div>
                  <div>Discipline in Physicality</div>
                  <div>Discipline in Relationships</div>
                  <div>Discipline in Business Production</div>
                  <br />
                  <div>I'm 28 and in the best shape of my life.</div>
                  <br />
                  <div>
                    I'm married to a queen and radically connected to my family
                    and kids after a NASTY separation.
                  </div>
                  <br />
                  <div>
                    I do not have drama anywhere in my life, or with anyone
                    (including my ex!)
                  </div>
                  <br />
                  <div>
                    My businesses are growing, and to be candid I've never had
                    this level of mental clarity in my 28 years on this rock.
                  </div>
                  <br />
                  <div>HOW?</div>
                  <br />
                  <div>
                    HOW EXACTLY have I done all this and made radical changes
                    that LAST?{" "}
                  </div>
                  <br />
                  <div>It's simple. </div>
                  <br />
                  <div>
                    <u>
                      <b>
                        I QUIT LOOKING FOR MORE INFORMATION, AND STARTED DOING
                        SHIT VERY DIFFERENTLY
                        <span
                          style={{
                            backgroundColor: "initial",
                            color: "inherit",
                          }}
                        >
                          .
                        </span>
                      </b>
                    </u>
                  </div>
                  <br />
                  <div>
                    <u>ACTIONS</u> that I have used over and over and actions I
                    have coached many people on.
                  </div>
                  <br />
                  <div>
                    <u>ACTIONS</u> that I have been refining and <u>TOOLS</u> I
                    have been sharpening for years.
                  </div>
                  <br />
                  <div>
                    ACTIONS and TOOLS I have trained both millionaires and
                    college kids with.
                  </div>
                  <br />
                  <div>
                    Understand something, information WILL NOT build your million dollar business.{" "}
                  </div>
                  <br />
                  <div>
                    Information WILL NOT get you in shape, deepen your
                    relationships or scale your business.{" "}
                  </div>
                  <br />
                  <div>Information does not make you grow.</div>
                  <br />
                  <div>
                    <u>
                      <b>VERY SPECIFIC TOOLS WILL. </b>
                    </u>
                  </div>
                  <br />
                  <div>
                    <u>
                      <b>VERY DELIBERATE ACTION WILL.</b>
                    </u>
                  </div>
                  <br />
                  <div>Every single day of your life. </div>
                  <br />
                  <div>
                    If you have read this far than you know there is something
                    to this, and to be honest if you have been following or
                    watching me on social media you'll KNOW that you have seen
                    me radically shift my life.
                  </div>
                  <br />
                  <div>
                    It's NOT magic how I came back from the NASTY separation.{" "}
                  </div>
                  <br />
                  <div>
                    It's NOT luck that I will grow my business to $1,000,000 a
                    year in under 12 months.
                  </div>
                  <br />
                  <div>It's NOT luck that I am married to my best friend. </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
          <div
            className="de elBTN elAlign_center elMargin0 ui-droppable de-editable"
            id="tmp_button-89797"
            data-de-type="button"
            data-de-editing="false"
            data-title="button"
            data-ce="false"
            data-trigger="none"
            data-animate="fade"
            data-delay="500"
            style={{
              marginTop: "40px",
              outline: "none",
              cursor: "pointer",
            }}
            aria-disabled="false"
            data-elbuttontype="1"
          >
            <a
              href="https://learn.hardworkdiscipline.com/offers/5d78cfd1-85ea-460c-b714-25d0fd00af5a"
              className="elButton elButtonSize1 elButtonColor1 elButtonRounded elButtonPadding2 elBtnVP_10 elButtonCorner3 elButtonFluid elBtnHP_25 elBTN_b_1 elButtonShadowN1 elButtonTxtColor1 mfs_25"
              style={{
                color: "rgb(255, 255, 255)",
                fontWeight: "600",
                backgroundColor: "rgb(255, 21, 0)",
                fontSize: "40px",
              }}
              rel="noopener noreferrer"
            >
              <span className="elButtonMain">
                CLICK TO GET A DISCIPLINED LIFE!
              </span>
              <span className="elButtonSub">Claim A Free 5 VIDEO COURSE</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
