const imageUrl = window.innerWidth >= 650 ? 'img/HWD-header.png' : 'img/HWD-header2.png';

export const HardWork = (props) => {
  return (
    <div
      className="container fullContainer noTopMargin padding20-top padding20-bottom padding40H noBorder borderSolid border3px cornersAll radius0 shadow0 bgNoRepeat emptySection"
      id="section--56134"
      data-title="Section"
      data-block-color="0074C7"
      style={{
        paddingTop: "20px",
        paddingBottom: "20px",
        outline: "none",
        backgroundColor: "rgb(0, 0, 0)",
      }}
      data-trigger="none"
      data-animate="fade"
      data-delay="500"
    >
      <div className="containerInner ui-sortable">
        <div
          className="row bgCover noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
          id="row--89742"
          data-trigger="none"
          data-animate="fade"
          data-delay="500"
          data-title="1 column row"
          style={{
            paddingTop: "0px",
            paddingBottom: "5px",
            margin: "0px",
            outline: "none",
          }}
        >
          <div
            id="col-full-136"
            className="col-md-12 innerContent col_left"
            data-col="full"
            data-trigger="none"
            data-animate="fade"
            data-delay="500"
            data-title="1st column"
            style={{ outline: "none" }}
          >
            <div
              className="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
              style={{ padding: "0 10px" }}
            >
              <div
                className="de elImageWrapper de-image-block elAlign_center elMargin0 ui-droppable de-editable"
                id="tmp_image-33549"
                data-de-type="img"
                data-de-editing="false"
                data-title="image"
                data-ce="false"
                data-trigger="none"
                data-animate="fade"
                data-delay="500"
                style={{
                  marginTop: "30px",
                  outline: "none",
                  cursor: "pointer",
                }}
                aria-disabled="false"
              >
                <img src={imageUrl} className="elIMG ximg" alt=""></img>
              </div>
              <div
                className="de elVideoWrapper de-video-block elVideoWidth100 elMargin0 ui-droppable de-editable"
                id="tmp_video-99898"
                data-de-type="video"
                data-de-editing="false"
                data-title="video"
                data-ce="false"
                data-trigger="none"
                data-animate="fade"
                data-delay="500"
                data-video-type="wistia"
                style={{
                  marginTop: "5px",
                  outline: "none",
                  cursor: "pointer",
                }}
                aria-disabled="false"
                data-wistia-url="https://l9fe.wistia.com/medias/ls7n0ypl1k"
              >
                <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                  <iframe
                    src="https://player.vimeo.com/video/712160428?h=d36118730c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;autoplay=1"
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                    title="video.mov"
                  ></iframe>
                </div>
              </div>
              <div
                className="de elHeadlineWrapper ui-droppable de-editable"
                id="tmp_headline1-94427"
                data-de-type="headline"
                data-de-editing="false"
                data-title="headline"
                data-ce="true"
                data-trigger="none"
                data-animate="fade"
                data-delay="500"
                style={{
                  marginTop: "30px",
                  outline: "none",
                  cursor: "pointer",
                  "fontFamily": "Merriweather, Helvetica, sans-serif",
                }}
                aria-disabled="false"
                data-google-font="Merriweather"
              >
                <h1
                  className="ne elHeadline hsSize3 lh4 elMargin0 elBGStyle0 hsTextShadow0 mfs_17"
                  style={{
                    textAlign: "center",
                    fontSize: "36px",
                    color: "rgb(255, 255, 255)",
                  }}
                  data-bold="inherit"
                  data-gramm="false"
                >
                  CLICK BELOW TO GET A FREE 5 VIDEO COURSE! IN IT COURSE YOU
                  WILL LEARN THE STEPS TO GET A DISCIPLINE LIFE!
                </h1>
              </div>
              <div
                className="de elBTN elAlign_center elMargin0 ui-droppable de-editable"
                id="tmp_button-89797"
                data-de-type="button"
                data-de-editing="false"
                data-title="button"
                data-ce="false"
                data-trigger="none"
                data-animate="fade"
                data-delay="500"
                style={{
                  marginTop: "40px",
                  outline: "none",
                  cursor: "pointer",
                }}
                aria-disabled="false"
                data-elbuttontype="1"
              >
                <a
                  href="https://learn.hardworkdiscipline.com/offers/5d78cfd1-85ea-460c-b714-25d0fd00af5a"
                  className="elButton elButtonSize1 elButtonColor1 elButtonRounded elButtonPadding2 elBtnVP_10 elButtonCorner3 elButtonFluid elBtnHP_25 elBTN_b_1 elButtonShadowN1 elButtonTxtColor1 mfs_25"
                  style={{
                    color: "rgb(255, 255, 255)",
                    fontWeight: "600",
                    backgroundColor: "rgb(255, 21, 0)",
                    fontSize: "40px",
                  }}
                  rel="noopener noreferrer"
                >
                  <span className="elButtonMain">
                    CLICK TO GET A DISCIPLINED LIFE!
                  </span>
                  <span className="elButtonSub">
                    Claim A Free 5 VIDEO COURSE
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
